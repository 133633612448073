import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import '../styles/DashboardLayout.css';

const DashboardLayout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="dashboard-container">
      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>
      <nav className={`dashboard-nav ${isMenuOpen ? 'open' : ''}`}>
        <ul>
          <li><Link to="/dashboard/bookings" onClick={() => setIsMenuOpen(false)}>Bookings</Link></li>
          <li><Link to="/dashboard/services" onClick={() => setIsMenuOpen(false)}>Services</Link></li>
          <li><Link to="/dashboard/availability" onClick={() => setIsMenuOpen(false)}>Availability</Link></li>
          <li><Link to="/dashboard/settings" onClick={() => setIsMenuOpen(false)}>Settings</Link></li>
        </ul>
      </nav>
      <main className="dashboard-content">
        <Outlet />
      </main>
    </div>
  );
};

export default DashboardLayout;
