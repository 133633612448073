// /src/dashboard/DashboardOverview.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/DashboardOverview.css';

const DashboardOverview = () => {
  const [upcomingBookings, setUpcomingBookings] = useState([]);
  const [summary, setSummary] = useState({ totalBookings: 0, averageRevenue: 0, topService: '' });
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchDashboardData = async () => {
      const token = localStorage.getItem('token');

      try {
        // Fetch upcoming bookings
        const bookingsRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/business-dashboard/bookings/today`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUpcomingBookings(bookingsRes.data);

        // Fetch summary analytics
        const summaryRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/business-dashboard/summary`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSummary(summaryRes.data);
      } catch (err) {
        setError('Error fetching dashboard data');
        console.error('Dashboard fetch error:', err);
      }
    };

    fetchDashboardData();
  }, []);

  return (
    <div className="dashboard-overview">
      <h2>Dashboard Overview</h2>
      {error && <p className="error">{error}</p>}

      <section className="upcoming-bookings">
        <h3>Upcoming Bookings</h3>
        {upcomingBookings.length > 0 ? (
          <ul>
            {upcomingBookings.map((booking) => (
              <li key={booking._id}>
                {booking.serviceName} - {booking.bookingTime.startTime} on {booking.bookingTime.day}
              </li>
            ))}
          </ul>
        ) : (
          <p>No bookings for today.</p>
        )}
      </section>

      <section className="summary">
        <h3>Summary</h3>
        <p>Total Bookings: {summary.totalBookings}</p>
        <p>Average Revenue: £{summary.averageRevenue}</p>
        <p>Top Service: {summary.topService || 'N/A'}</p>
      </section>
    </div>
  );
};

export default DashboardOverview;
