// src/HomePage.js
import React from 'react';

function HomePage() {
  return (
    <div>
      <h1>Welcome to the Admin Dashboard</h1>
    </div>
  );
}

export default HomePage;
