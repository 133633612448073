import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection

const Settings = () => {
  const [businessAddress, setBusinessAddress] = useState('');
  const [pricePerMile, setPricePerMile] = useState('');
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [isEditingPrice, setIsEditingPrice] = useState(false);

  const navigate = useNavigate(); // Initialize navigate for redirecting

  useEffect(() => {
    const fetchSettings = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/business-dashboard/settings`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        setBusinessAddress(response.data.businessAddress || '');
        setPricePerMile(response.data.pricePerMile || '');
      } catch (err) {
        setError('Error fetching settings: ' + (err.response?.data?.error || 'Unknown error'));
      }
    };
    fetchSettings();
  }, []);

  const saveSettings = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/business-dashboard/settings`,
        { businessAddress, pricePerMile },
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      setMessage(response.data.message);
      setIsEditingAddress(false);
      setIsEditingPrice(false);
    } catch (err) {
      setError('Error saving settings: ' + (err.response?.data?.error || 'Unknown error'));
    }
  };

  const handleSignOut = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div>
      <h2>Settings</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {message && <p style={{ color: 'green' }}>{message}</p>}

      {/* Business Address Section */}
      <label>Business Address:</label>
      {isEditingAddress ? (
        <div>
          <input
            type="text"
            value={businessAddress}
            onChange={(e) => setBusinessAddress(e.target.value)}
            placeholder="Enter your business address"
          />
          <button onClick={saveSettings}>Save</button>
          <button onClick={() => setIsEditingAddress(false)}>Cancel</button>
        </div>
      ) : (
        <div className="displayed-info">
          <span>{businessAddress || 'Not set'}</span>
          <button onClick={() => setIsEditingAddress(true)}>Edit</button>
        </div>
      )}

      {/* Price Per Mile Section */}
      <label>Price per Mile:</label>
      {isEditingPrice ? (
        <div>
          <input
            type="number"
            value={pricePerMile}
            onChange={(e) => setPricePerMile(e.target.value)}
            placeholder="Enter price per mile"
          />
          <button onClick={saveSettings}>Save</button>
          <button onClick={() => setIsEditingPrice(false)}>Cancel</button>
        </div>
      ) : (
        <div className="displayed-info">
          <span>£{pricePerMile}</span>
          <button onClick={() => setIsEditingPrice(true)}>Edit</button>
        </div>
      )}

      {/* Sign Out Button */}
      <div style={{ marginTop: '20px' }}>
        <button onClick={handleSignOut} style={{ color: 'red' }}>
          Sign Out
        </button>
      </div>
    </div>
  );
};

export default Settings;

