import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Services.css';

const Services = () => {
  const [services, setServices] = useState([]);
  const [newService, setNewService] = useState({ serviceName: '', price: '', duration: '' });
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchServices = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/business-dashboard/services`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setServices(response.data.services);
      } catch (err) {
        setError('Error fetching services: ' + (err.response?.data?.error || 'Unknown error'));
      }
    };
    fetchServices();
  }, []);

  const handleAddService = async (e) => {
    e.preventDefault();
    if (newService.price <= 0 || newService.duration <= 0) {
      setError('Price and duration must be positive values');
      return;
    }
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/business-dashboard/service/add`, newService, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setMessage('Service added successfully');
      setServices([...services, response.data.service]);
      setNewService({ serviceName: '', price: '', duration: '' });  // Reset form
    } catch (err) {
      setError('Error adding service: ' + (err.response?.data?.error || 'Unknown error'));
    }
  };

  return (
    <div className="services-container">
      <h2>Services</h2>
      {error && <p className="error-message">{error}</p>}
      {message && <p className="success-message">{message}</p>}

      <div className="services-list">
        {services.length > 0 ? (
          services.map((service) => (
            <div key={service._id || service.serviceName} className="service-card">
              <h3>{service.serviceName}</h3>
              <p><strong>Price:</strong> ${service.price}</p>
              <p><strong>Duration:</strong> {service.duration} minutes</p>
            </div>
          ))
        ) : (
          <p>No services available</p>
        )}
      </div>

      <h3>Add a New Service</h3>
      <form onSubmit={handleAddService} className="add-service-form">
        <label>Service Name:</label>
        <input
          type="text"
          value={newService.serviceName}
          onChange={(e) => setNewService({ ...newService, serviceName: e.target.value })}
          required
        />
        <label>Price ($):</label>
        <input
          type="number"
          min="0.01"
          step="0.01"
          value={newService.price}
          onChange={(e) => setNewService({ ...newService, price: e.target.value })}
          required
        />
        <label>Duration (minutes):</label>
        <input
          type="number"
          min="1"
          step="1"
          value={newService.duration}
          onChange={(e) => setNewService({ ...newService, duration: e.target.value })}
          required
        />
        <button type="submit" className="add-service-button">Add Service</button>
      </form>
    </div>
  );
};

export default Services;
