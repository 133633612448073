import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  console.log("ProtectedRoute: Token Found?", !!token);  // Debugging statement
  
  if (!token) {
    console.log("ProtectedRoute: Redirecting to /login");  // Debugging statement
    return <Navigate to="/login" />;
  }
  
  return children;
};

export default ProtectedRoute;
