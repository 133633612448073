// src/components/Availability.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Availability.css';

const Availability = () => {
  const [availability, setAvailability] = useState([]);
  const [newAvailability, setNewAvailability] = useState({ day: '', startTime: '', endTime: '' });
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchAvailability = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/business-dashboard/availability`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setAvailability(response.data);
      } catch (err) {
        setError('Error fetching availability: ' + (err.response?.data?.error || 'Unknown error'));
      }
    };
    fetchAvailability();
  }, []);

  const handleAddAvailability = async (e) => {
    e.preventDefault();
    if (newAvailability.startTime >= newAvailability.endTime) {
      setError('Start time must be earlier than end time');
      return;
    }
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/business-dashboard/availability/add`, newAvailability, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setMessage('Availability added successfully');
      setAvailability([...availability, response.data.availability[response.data.availability.length - 1]]);
      setNewAvailability({ day: '', startTime: '', endTime: '' });
    } catch (err) {
      setError('Error adding availability: ' + (err.response?.data?.error || 'Unknown error'));
    }
  };

  return (
    <div className="availability-container">
      <h2>Availability</h2>
      {error && <p className="error-message">{error}</p>}
      {message && <p className="success-message">{message}</p>}

      <div className="availability-grid">
        {availability.length > 0 ? (
          availability.map((slot, index) => (
            <div key={index} className="availability-card">
              <p><strong>{slot.day}</strong></p>
              <p>{slot.startTime} - {slot.endTime}</p>
            </div>
          ))
        ) : (
          <p>No availability added yet</p>
        )}
      </div>

      <h3>Add New Availability</h3>
      <form onSubmit={handleAddAvailability} className="availability-form">
        <label>Day:</label>
        <select
          value={newAvailability.day}
          onChange={(e) => setNewAvailability({ ...newAvailability, day: e.target.value })}
          required
        >
          <option value="">Select a day</option>
          <option value="Monday">Monday</option>
          <option value="Tuesday">Tuesday</option>
          <option value="Wednesday">Wednesday</option>
          <option value="Thursday">Thursday</option>
          <option value="Friday">Friday</option>
          <option value="Saturday">Saturday</option>
          <option value="Sunday">Sunday</option>
        </select>
        
        <label>Start Time:</label>
        <input
          type="time"
          value={newAvailability.startTime}
          onChange={(e) => setNewAvailability({ ...newAvailability, startTime: e.target.value })}
          required
        />
        <label>End Time:</label>
        <input
          type="time"
          value={newAvailability.endTime}
          onChange={(e) => setNewAvailability({ ...newAvailability, endTime: e.target.value })}
          required
        />
        <button type="submit" className="add-availability-button">Add Availability</button>
      </form>
    </div>
  );
};

export default Availability;
