import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Booking.css';

const Bookings = () => {
  const [bookings, setBookings] = useState([]);
  const [error, setError] = useState('');
  const [isRescheduling, setIsRescheduling] = useState(null); // Tracks which booking is being rescheduled
  const [newTime, setNewTime] = useState(''); // Holds the new date and time for rescheduling

  useEffect(() => {
    const fetchBookings = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/business-dashboard/bookings`,
          {
            headers: { 'Authorization': `Bearer ${token}` },
          }
        );
        
        const updatedBookings = await Promise.all(response.data.map(async (booking) => {
          try {
            const durationResponse = await axios.get(
              `${process.env.REACT_APP_API_BASE_URL}/api/business/get-service-duration?businessId=${booking.businessId}&serviceId=${booking.serviceId}`
            );
            booking.duration = durationResponse.data.duration;
          } catch (durationError) {
            console.error('Error fetching service duration:', durationError);
          }
          return booking;
        }));

        setBookings(updatedBookings);
      } catch (err) {
        setError('Error fetching bookings: ' + (err.response?.data?.error || 'Unknown error'));
      }
    };
    fetchBookings();
  }, []);

  const updateBookingStatus = async (bookingId, action) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/business-dashboard/booking/${action}/${bookingId}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.message) {
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking._id === bookingId
              ? {
                  ...booking,
                  status: action === 'approve' ? 'approved' : 'declined',
                  customerEmail: response.data.booking.customerEmail,
                  phoneNumber: response.data.booking.phoneNumber,
                  customerAddress: response.data.booking.customerAddress,
                  firstName: response.data.booking.firstName,
                  lastName: response.data.booking.lastName,
                }
              : booking
          )
        );
      }
    } catch (err) {
      setError(`Error updating booking: ${err.response?.data?.error || 'Unknown error'}`);
    }
  };

  const rescheduleBooking = async (bookingId, newTime) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/business-dashboard/booking/reschedule`,
        { bookingId, newTime, requestor: 'business' },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      if (response.data.message) {
        // Update the booking with the new status and date
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking._id === bookingId
              ? { ...booking, status: 'rescheduled', bookingTime: { day: newTime.split('T')[0], startTime: newTime.split('T')[1] } }
              : booking
          )
        );
      }
    } catch (err) {
      setError(`Error rescheduling booking: ${err.response?.data?.error || 'Unknown error'}`);
    }
  };
  

  return (
    <div className="bookings-container">
      <h2>Bookings</h2>
      {error && <p className="error-message">{error}</p>}
      {bookings.length > 0 ? (
        <div className="booking-cards">
          {bookings.map((booking) => (
            <div key={booking._id} className="booking-card">
              <h3>Service: {booking.serviceName}</h3>
              <p><strong>Date:</strong> {booking.bookingTime?.day || 'Unknown'}</p>
              <p><strong>Time:</strong> {booking.bookingTime?.startTime || 'Unknown'} - {booking.bookingTime?.endTime || 'Unknown'}</p>
              <p><strong>Duration:</strong> {booking.duration} minutes</p>
              
              <p><strong>Car Make:</strong> {booking.carBrand}</p>
              <p><strong>Body Style:</strong> {booking.carBodyType}</p>
              
              <p><strong>Total Quote:</strong> ${booking.totalQuote}</p>
              
              <h4>Customer Details</h4>
              <p><strong>Name:</strong> {booking.status === 'approved' ? `${booking.firstName} ${booking.lastName}` : `${booking.firstName} ${booking.lastName.charAt(0)}.`}</p>

              {booking.status === 'approved' && booking.customerEmail && (
                <p><strong>Email:</strong> {booking.customerEmail}</p>
              )}
              {booking.status === 'approved' && booking.phoneNumber && (
                <p><strong>Phone:</strong> {booking.phoneNumber}</p>
              )}
              <p><strong>Customer Location:</strong> {booking.status === 'approved' ? booking.customerAddress : booking.customerAddress?.split(',').slice(1).join(', ') || 'Location unavailable'}</p>

              <p><strong>Status:</strong> {booking.status}</p>
              
              {booking.status === 'pending' && (
                <div className="action-buttons">
                <button onClick={() => updateBookingStatus(booking._id, 'approve')} className="approve-btn">Approve</button>
                <button onClick={() => rescheduleBooking(booking._id, prompt("Enter new date and time (e.g., 2024-12-30T10:00)"))} className="reschedule-btn">Reschedule</button>
                <button onClick={() => updateBookingStatus(booking._id, 'decline')} className="decline-btn">Cancel</button>
              </div>
              
              )}

              {booking.status === 'approved' && (
                <button onClick={() => setIsRescheduling(booking._id)} className="reschedule-btn">
                  Reschedule
                </button>
              )}

              {isRescheduling === booking._id && (
                <div className="reschedule-form">
                  <input
                    type="datetime-local"
                    value={newTime}
                    onChange={(e) => setNewTime(e.target.value)}
                  />
                  <button onClick={() => rescheduleBooking(booking._id)} className="confirm-reschedule-btn">
                    Confirm Reschedule
                  </button>
                  <button onClick={() => setIsRescheduling(null)} className="cancel-reschedule-btn">
                    Cancel
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p>No bookings available</p>
      )}
    </div>
  );
};

export default Bookings;
